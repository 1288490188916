export function getRedirectRoute(idToken?: string): string {
    const params: URLSearchParams = new URLSearchParams(window.location.search)
    const redirect: string = params.get('redirect')
    const redirectAuth: string = params.get('redirectAuth')
    if (redirectAuth) {
        const url = new URL(redirect)
        const hostname = url.hostname
        return `https://${hostname}/auth#token=${idToken}&location=${redirect}`
    }
    return redirect || process.env.REACT_APP_PLATFORM_LANDING
}
